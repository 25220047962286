@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 5px;
    }
    *{
        font-size: 15px;
    }
    .odd {
        background: blue;
    }
    .even {
        background: white;
    }
    .big-checkbox {
        width: 30px;
        height: 30px;
    }

    .mgRadioLabel{
        margin-left:3px;
        font-weight:normal;
    }

    .mgLabel{
        margin-right:3px;
        font-weight:normal;
    }
    .mgLabelList {
        font-weight: normal;
        margin-left: 30px;
        margin-right: 10px;
    }

    .centerVerticalPanel {
        position: absolute;
        top: 50%;
        left: 25px;
        right: 25px;
        transform: translateY(-50%);
        background-color: #122d56;
        border: 2px;
        border-radius: 5px;
    }
    .centerVerticalPanelNoBackgroundColor {
        position: absolute;
        top: 50%;
        left: 25px;
        right: 25px;
        transform: translateY(-50%);
        border: 2px;
        border-radius: 5px;
    }

    .mg-margins{
        margin-left:15px;
        margin-right:15px;
    }
    .mg_blue_font {
        color: #122d56;
    }
    .mg_group_section {
        background: #26d07c;
        color: white;


    }

    .mg_red_font {
        color: darkred;
    }

    .mg_blue_border {
        border: 5px;
        border-style: solid;
        border-color: #122d56;
    }
    .mg_green_font {
        color: #26d07c;
    }
    .mg-white {
        color: white;
    }
    .mg_button_green {
        background: #26d07c;
        color: #122d56;
        border-radius: 5px;
        border-color: darkgray;
        border-width: 1px;
        height: 40px;
        display: block;
        width: 70%;
        text-align: center;
        font-size: 16pt;
        margin-bottom: 10px;
    }

    .mg_button {
        border-radius: 5px;
        border-color: darkgray;
        border-width: 1px;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        font-size: 14pt;
        margin-bottom: 10px;
    }
    .mg_button_block {
        border-radius: 5px;
        border-color: darkgray;
        border-width: 1px;
        display: block;
        width: 70%;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        font-size: 14pt;
        margin-bottom: 10px;
    }
    input[type="radio"] {
        -webkit-transform: scale(1.5);  
        transform: scale(1.5);
    }

    .spinner {
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #3498db;
        width: 10px;
        height: 25px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
    }
    .glyphicon.spinning {
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
